<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :date-filter="dateFilter"
        :employees-filter="employeesFilter"
        :promotion-types-filter="promotionTypesFilter"
        :status-filter="statusFilter"
        :type-filter="typeFilter"
        :created-by-myself-filter="createdByMyselfFilter"
        @updateCreatedByMyselfFilter="updateCreatedByMyselfFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updatePromotionTypesFilter="updatePromotionTypesFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateTypeFilter="updateTypeFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-promotions="selectedPromotions"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        :search-type="searchType"
        :search-options="searchOptions"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedPromotions="selectedPromotions = $event"
        @getPromotions="getPromotions"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :selected-promotions="selectedPromotions"
          @updateSelectedPromotions="selectedPromotions = $event"
          @updatedPreOrderInfo="updatedPreOrderInfo"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      createdByMyselfFilter: false,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      itemPerPage: 50,
      promotionTypesFilter: [],
      searchKey: null,
      searchType: 0,
      searchOptions: [
        { type: "searchName", label: "Theo tên khuyến mại" },
        { type: "searchProduct", label: "Theo mã, tên sản phẩm" },
        { type: "searchSku", label: "Theo mã SKU/MPN" }
      ],
      selectedPromotions: [],
      statusFilter: null,
      typeFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getPromotions(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getPromotions(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "promotion",
        query: {
          type:
            this.typeFilter && this.typeFilter !== "all"
              ? this.typeFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter.length > 0
              ? this.statusFilter
              : undefined,
          created_by:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          created_by_myself_filter: this.createdByMyselfFilter
            ? this.createdByMyselfFilter
            : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          promotion_types:
            this.promotionTypesFilter && this.promotionTypesFilter.length > 0
              ? this.promotionTypesFilter
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getPromotions(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);

        // Set createdByMySelf
        this.createdByMyselfFilter = query.created_by_myself_filter
          ? true
          : false;
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered employees
        this.employeesFilter =
          typeof query.created_by === "string"
            ? [parseInt(query.created_by)]
            : typeof query.created_by === "object"
            ? query.created_by.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);

        // Set promotion type
        this.promotionTypesFilter =
          typeof query.promotion_types === "string"
            ? [parseInt(query.promotion_types)]
            : typeof query.promotion_types === "object"
            ? query.promotion_types.map(item => parseInt(item))
            : [];
        // Set search key
        this.searchKey = query.search || null;
        // Set search type
        this.searchType = query.search_type ? parseInt(query.search_type) : 0;
        // Set filtered status
        this.statusFilter =
          typeof query.status === "string"
            ? [parseInt(query.status)]
            : typeof query.status === "object"
            ? query.status.map(item => parseInt(item))
            : [];
        // Set filtered type
        this.typeFilter = query.type ? parseInt(query.type) : "all";

        // Get promotions
        await this.$store.dispatch("PROMOTION/getPromotions", {
          filter: {
            type:
              this.typeFilter && this.typeFilter !== "all"
                ? [this.typeFilter]
                : null,
            status: this.statusFilter,
            created_by: this.employeesFilter,
            created_by_myself: this.createdByMyselfFilter
              ? this.createdByMyselfFilter
              : undefined,
            promotion_types: this.promotionTypesFilter.length
              ? this.promotionTypesFilter
              : undefined,
            time_from:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            time_to:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          searchName:
            this.searchOptions[this.searchType].type === "searchName"
              ? this.searchKey
              : null,
          searchProduct:
            this.searchOptions[this.searchType].type === "searchProduct"
              ? this.searchKey
              : null,
          searchSku:
            this.searchOptions[this.searchType].type === "searchSku"
              ? this.searchKey
              : null,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async updatedPreOrderInfo() {
      const route = this.$route;
      await this.getPromotions(route.query);
    },

    updateCreatedByMyselfFilter(val) {
      this.createdByMyselfFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updatePromotionTypesFilter(val) {
      this.promotionTypesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }

      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTypeFilter(val) {
      this.typeFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>
